import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Play from './pages/Play';


const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" Component={Home} />
            <Route path="/play" element={<Play />} />
        </Routes>
    );
};

export default AppRouter;