import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import 'core-js/es/promise';
import 'whatwg-fetch';
import 'core-js/es/array';
import 'core-js/es/object';
import 'core-js/es/symbol';
import 'core-js/es/string';
import 'core-js/es/number';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

